<template>
	<main
		class="container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="finished-build-page container-base">
			<Breadcrumbs/>
			<div class="finished-build-page__content">
				<div class="finished-build-page__info">
					<img
						src="~@/assets/img/icons/success.svg"
						alt="Success"
					>
					<h2 class="finished-build-page__title">Вы завершили сборку заказа!</h2>
					<h3 class="finished-build-page__subtitle">Заказ будет оформлен после подтверждения инициатором</h3>
				</div>
				<router-link
					to="/"
					class="btn finished-build-page__btn"
				>
					Перейти на главную
				</router-link>
			</div>
		</section>
	</main>
</template>
<script>
import {mobileCheck} from "@/helpers";
import {mapActions} from "vuex";

export default {
	name: "FinishedBuildPage",
	components: {
		Breadcrumbs: () => import("@/components/nav/Breadcrumbs.vue"),
	},
	methods: {
		mobileCheck,
		...mapActions({
			resetCartToDefault: "resetCartToDefault",
		})
	},
	mounted()
	{
		this.resetCartToDefault();
	}
}
</script>
<style lang="scss">
.finished-build-page
{
	display: flex;
	flex-direction: column;
}

.finished-build-page__content
{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 40px;
	padding: 56px 0 124px 0;
}

.finished-build-page__info
{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	gap: 24px;
}

.finished-build-page__title
{
	font-size: 40px;
	line-height: 48px;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.finished-build-page__subtitle
{
	font-size: 16px;
	line-height: 24px;
	font-family: $mainFontNew;
	color: $textColor;
}

.finished-build-page__btn
{
	cursor: pointer;
	text-align: center;
	padding: 16px 32px;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
}
</style>